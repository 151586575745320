import React, { createRef, useState, useEffect } from "react";
import loadable from '@loadable/component'
import tw, { styled } from 'twin.macro';
import {roundRect, setStrokeProps} from '../utils/graphUtils.js'
import TwConfig from "../../tailwind.config.js"

const ForceGraph2D = loadable(() => import('./forceGraph2d'))
const colors = TwConfig.theme.extend.colors

const background = '#f5f5f5'

const Div = styled.div`
  ${tw`
  absolute top-0 left-0 overflow-hidden
  `}
  z-index: 1;
`;

const refCallback = (ref) => {
  if (ref){
    ref.d3Force("charge").strength(-300);
  }
}

const drawText  = (node, ctx, globalScale) => {
  // see for globalScale usage:
  // https://github.com/vasturiano/react-force-graph/blob/master/example/text-nodes/index-2d.html
  // set font
  ctx.font = `${node.fontWeight} ${node.fontSize}px Montserrat`;
  // set text location
  const textOffsetMultiplier = node.type === "company" ? 1.35 : 1.2
  const X = node.x 
  const Y = node.type === "category" ? node.y : node.y + node.val/4 * textOffsetMultiplier
  // draw background
  const textWidth = ctx.measureText(node.name).width;
  const bckgDimensions = [textWidth, node.fontSize].map(n => n + node.fontSize * 0.2); // some padding
  ctx.fillStyle = node.type === "category" ? "#fff" : background;
  ctx.fillRect(X - bckgDimensions[0] / 2, Y - bckgDimensions[1] / 2, ...bckgDimensions);
  // draw text
  ctx.fillStyle = node.textColor;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(node.name, X, Y);
  return ctx;
}

const drawNodes = (node, ctx) => {
  // set variables
  const radius = node.val/4
  const cornerRadius = 3
  ctx.fillStyle = node.nodeColor;

  // if category, draw a circle
  if (node.type === "category"){
    ctx.fillStyle = "#fff";
    // circle path
    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI);

    ctx = setStrokeProps(ctx, node.nodeColor, 5);
    ctx.stroke();
    // fill
    ctx.fill();
  }

  // if subcategory, draw a circle
  if (node.type === "subcategory"){
    // circle path
    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI);
    // thick white stroke for seperation
    ctx = setStrokeProps(ctx, background, 3);
    ctx.stroke();
    // fill
    ctx.fill();
  }

  // if company, draw rounded rect
  if (node.type === "company"){
    // thick white stroke for seperation
    ctx = setStrokeProps(ctx, background, 3);
    ctx = roundRect(ctx, node.x-radius, node.y-radius, 
      radius*2, radius*2, cornerRadius, false, true);
    // stroke and fill of rounded rect
    ctx = setStrokeProps(ctx, colors.nepal, 0.5);
    ctx = roundRect(ctx, node.x-radius, node.y-radius, 
      radius*2, radius*2, cornerRadius, true, true);
  }
  return ctx;
}

const drawLogos = (node, ctx) => {
  // set variables and save context before translation/scaling
  ctx.fillStyle = node.logoColor; 
  ctx.beginPath();
  ctx.save(); 
  // draw logo
  const p = new Path2D(node.logoSvg)
  const offset = (100 * node.logoScaleFactor)/2
  // translate/scale, then fill and restore
  ctx.translate(node.x-offset, node.y-offset)
  ctx.scale(node.logoScaleFactor,node.logoScaleFactor)
  ctx.fill(p);
  ctx.restore();
  return ctx;
}

const GraphCanvas = ({ graphData }) => {

  const graphContainer = createRef();
  // https://github.com/gatsbyjs/gatsby/issues/5835
  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({height: 0, width: 0})
  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }
  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [hasRan, screenSize])

  return (
    <Div ref={graphContainer}> 
      <ForceGraph2D
        ref={refCallback}
        graphData={graphData}
        backgroundColor={background} 
        nodeRelSize={2}
        forceEngine={"d3"}
        d3VelocityDecay={0.6}
        d3AlphaDecay={0.0228}
        d3AlphaMin= {0}
        cooldownTime={3000}
        width={screenSize.width}
        height={screenSize.height}
        linkWidth={(link) => link.type === "subcategory-company" ? 0.25 : 0.5}
        linkOpacity={0.5}
        linkHoverPrecision={4}
        linkColor={(link) => link.color ? link.color : colors.predarkgrey}
        nodeVisibility={(node) => node.nodeVisibility}
        linkVisibility={(link) => link.linkVisibility}
        enableZoomPanInteraction={true}
        enablePointerInteraction={true}	
        onNodeClick={(node, event) => {
          if (node.type === "company"){
            window.open(node.website, '_blank');
          }
        }}        
        onNodeHover={(node, prevNode) => {
          // if (node && node.type === "company"){
          //   node.nodeColor="#ffffff";
          //   node.logoColor=colors.nepal
          //   console.log(node)
          // }
          // if (prevNode && prevNode.type === "company"){
          //   prevNode.nodeColor=colors.nepal
          //   prevNode.logoColor=colors.darkgrey
          // }
          graphContainer.current.style.cursor = node && node.type === "company" ? "pointer" : null
        }}
        nodeCanvasObject={(node, ctx, globalScale) => {
          ctx = drawNodes(node, ctx)
          ctx = drawText(node, ctx, globalScale)
          ctx = drawLogos(node, ctx)
        }}
      />
    </Div>
  )
}

export default GraphCanvas;