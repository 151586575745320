//
// for dev (files are airtable + svg paths)
//
// import companies
import company from "../../data/dev/company.json"

// import categories
import domain from "../../data/dev/domain.json"
import data_type from "../../data/dev/data_type.json"
import disease from "../../data/dev/disease.json"

// load files
import { prepareData } from "./graphUtils"
import { graphVars } from "./graphVars"

const MODE = "DEV"

// select Categories here
// Categories will look th same regardless if DEV or PROD
// Decided to only have one category as active. Combining categoires doesnt make sense.
// on page landing:
// active==true i.e. category data will be included in graph (all subcategories)
// uiActive==true i.e. category button will be active in UI
// all==true i.e. all button for category will be active
const Categories = [
  {
    data: domain,
    id: "3a21d538-1462-4b4b-beee-4469f1ccaeb8",
    active: true,
    uiActive: true,
    all: true,
    fields: {
      name: "Domain",
    },
    nodeColor: "#424242",
    nodeColorTw: "darkgrey",
    textColor: "#424242",
  },
  {
    data: data_type,
    id: "6c2e5088-3f0e-4c43-9b11-8ae89bf54b04",
    active: false,
    uiActive: false,
    all: false,
    fields: {
      name: "Data Type",
    },
    nodeColor: "#424242",
    nodeColorTw: "darkgrey",
    textColor: "#424242",
  },
  {
    data: disease,
    id: "018495d5-49d4-41f5-9aa8-dee4e1e3566e",
    active: false,
    uiActive: false,
    all: false,
    fields: {
      name: "Disease",
    },
    nodeColor: "#424242",
    nodeColorTw: "darkgrey",
    textColor: "#424242",
  },
]

// add 2 activity booleans for each sub categories
// should not need to change this
// active==true i.e. subcategory data will be included in graph
// uiActive==false i.e. subcategory button will not be active in UI
Categories.forEach((category) => {
  category.data.forEach((subcategory) => {
    subcategory.active = true
    subcategory.uiActive = false
  })
})

// Prepare data if DEV
let rawGraphData
if (MODE === "DEV") {
  rawGraphData = prepareData(company, Categories, graphVars)
  console.log("DEV mode. Companies:", company.length)
}
if (MODE === "PROD") {
  // import the other files right away.
  // they should be good to go right away into filter then GraphCanvas
}

export { Categories, rawGraphData }
