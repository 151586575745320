import TwConfig from "../../tailwind.config.js"

export function prepareData(company, Categories, graphVars) {
  // this must be kept mirrored to the python version

  const graphData = {
    nodes: [],
    links: [],
  }

  // Add companies
  const Companies = company.map((x) => {
    return {
      id: x.id,
      name: x.fields.name,
      website: x.fields.website,
      val: graphVars.company.size,
      textColor: TwConfig.theme.extend.colors.darkgrey,
      nodeColor: TwConfig.theme.extend.colors.nepal,
      logoColor: TwConfig.theme.extend.colors.darkgrey,
      type: "company",
      logoScaleFactor: graphVars.company.logoScaleFactor,
      logoSvg: x.fields.logo_svg,
      fontSize: graphVars.company.fontSize,
      fontWeight: graphVars.company.fontWeight,
      nodeVisibility: true,
    }
  })
  graphData.nodes.push(...Companies)

  // Add categories
  Categories.forEach((category) => {
    // add one node for each category
    graphData.nodes.push({
      id: category.id,
      name: category.fields.name,
      val: graphVars.category.size,
      textColor: category.textColor,
      nodeColor: category.nodeColor,
      logoColor: "#ffffff",
      type: "category",
      belongsTo: "null",
      logoScaleFactor: graphVars.category.logoScaleFactor,
      logoSvg: "",
      fontSize: graphVars.category.fontSize,
      fontWeight: graphVars.category.fontWeight,
      nodeVisibility: true,
    })
    // now lets loop through subcategories
    category.data.forEach((subCategory) => {
      // add links from category to subcategory
      graphData.links.push({
        source: category.id,
        target: subCategory.id,
        linkVisibility: true,
        type: "category-subcategory",
        color: category.nodeColor,
      })
      // push one node for each subcategory
      graphData.nodes.push({
        id: subCategory.id,
        name: subCategory.fields.name,
        val: graphVars.subcategory.size,
        textColor: category.textColor,
        nodeColor: category.nodeColor,
        logoColor: "#ffffff",
        type: "subcategory",
        belongsTo: category.id,
        logoScaleFactor: graphVars.subcategory.logoScaleFactor,
        logoSvg: subCategory.fields.logo_svg,
        fontSize: graphVars.subcategory.fontSize,
        fontWeight: graphVars.subcategory.fontWeight,
        nodeVisibility: true,
      })
      // now lets loop though companies in each subcategory
      // add links from subcategories to companies
      subCategory.fields.company.forEach((companyId) => {
        graphData.links.push({
          source: subCategory.id, // gets replaced by actual node
          target: companyId, // gets replaced by actual node
          linkVisibility: true,
          type: "subcategory-company",
          // color always blue-900
        })
      })
    })
  })

  return graphData
}

// https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-on-html-canvas
/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
export function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === "undefined") {
    stroke = true
  }
  if (typeof radius === "undefined") {
    radius = 5
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius }
  } else {
    const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 }
    for (const side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side]
    }
  }
  ctx.beginPath()
  ctx.moveTo(x + radius.tl, y)
  ctx.lineTo(x + width - radius.tr, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
  ctx.lineTo(x + width, y + height - radius.br)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height)
  ctx.lineTo(x + radius.bl, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
  ctx.lineTo(x, y + radius.tl)
  ctx.quadraticCurveTo(x, y, x + radius.tl, y)
  ctx.closePath()
  if (fill) {
    ctx.fill()
  }
  if (stroke) {
    ctx.stroke()
  }
  return ctx
}

export function setStrokeProps(ctx, strokeStyle, lineWidth) {
  ctx.strokeStyle = strokeStyle
  ctx.lineWidth = lineWidth
  return ctx
}
