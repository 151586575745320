// variables to adjust
// NOTE: radius of nodes is size or val divided by 4

export const graphVars = {
  company: {
    size: 35,
    logoScaleFactor: 0.13,
    fontSize: 4,
    fontWeight: 500,
  },
  subcategory: {
    size: 100,
    logoScaleFactor: 0.3,
    fontSize: 8,
    fontWeight: 800,
  },
  category: {
    size: 100,
    logoScaleFactor: 0.45,
    fontSize: 10,
    fontWeight: 800,
  },
}
