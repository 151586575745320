// for categories
// only one category is active at a given time
// 1. if the category button that was clicked is not active (clicking on active category will not do anything)
// 2. reset all its subcategories to be active=true, but uiActive=false
// 3. set its own flags (active, uiActive, and all) to true
// 4. for all other categories, set flags (active, uiActive, and all) to false
export const handleClickCategory = (items, setItems, e) => {
  const currentActive = items.find((x) => x.id === e.value)
  if (!currentActive.active) {
    const editedItems = items.map((item) => {
      if (item.id === e.value) {
        const editedData = item.data.map((subItem) => {
          return { ...subItem, active: true, uiActive: false }
        })
        return {
          ...item,
          data: editedData,
          active: true,
          uiActive: true,
          all: true,
        }
      }
      return { ...item, active: false, uiActive: false, all: false }
    })
    setItems(editedItems)
  }
}

// subcategory filterType B
// only one subcategory is active at a given time
// 1. get active category and its subcategories
// 2. if the clicked subcategory is not uiActive (it might be data active if all is active)
// 3. loop though catgeories, if active..
// 4. loop though subcategories, if same id
// 5. set subcategory flags (active and uiActive) to true
// 6. set other subcategories flags (active and uiActive) to false
// 7. set category (all) flag to false, pass new subcategory data
// 8. for other categories, return them as is
export const handleClickSubcategoryFilterTypeB = (items, setItems, e) => {
  const allSubcategories = items.find((x) => x.active).data
  const currentActive = allSubcategories.find((x) => x.id === e.value)
  if (!currentActive.uiActive) {
    const editedItems = items.map((item) => {
      if (item.active) {
        const editedData = item.data.map((subItem) => {
          if (subItem.id === e.value) {
            return { ...subItem, active: true, uiActive: true }
          }
          return { ...subItem, active: false, uiActive: false }
        })
        return { ...item, data: editedData, all: false }
      }
      return item
    })
    setItems(editedItems)
  }
}

// if an "all" button is added, this will handle its click
// 1. if the current active category has all = false
// 2. loop through categories, if it is active
// 3. loop through subcategories
// 4. set all subcategories active=true for data, but uiActive=false, to get the "all" effect
// 5. set category (all) flag to true for that category, pass new subcategory data
// 6. for other categories, return them as is
export const handleClickSubcategoryAll = (items, setItems, e) => {
  if (!items.find((x) => x.active).all) {
    const editedItems = items.map((item) => {
      if (item.active) {
        const editedData = item.data.map((subItem) => {
          return { ...subItem, active: true, uiActive: false }
        })
        return { ...item, data: editedData, all: true }
      }
      return item
    })
    setItems(editedItems)
  }
}

// [NOT USED]
// for subcategories
// filterType A
// multiple can be active at a given time
// clicking on active one makes it inactive
// doesn't really work with "all button"
export const handleClickSubcategoryFilterTypeA = (items, setItems, e) => {
  // category loop
  const editedItems = items.map((item) => {
    if (item.active) {
      // subcategory loop
      const editedData = item.data.map((subItem) => {
        if (subItem.id === e.target.id) {
          return { ...subItem, active: !subItem.active }
        }
        return subItem
      })
      //
      return { ...item, data: editedData }
    }
    return item
  })
  setItems(editedItems)
}
