import React from "react"
import tw, { styled } from "twin.macro"
import Select from "react-select"
import {
  handleClickCategory,
  handleClickSubcategoryFilterTypeB,
  handleClickSubcategoryAll,
} from "../utils/filterUtils"
import TwConfig from "../../tailwind.config.js"

const GraphControlsDropdown = ({ items, setItems, type, allButton, index }) => {
  const Div = styled.div`
    ${tw`
    lg:pl-16 p-2 mr-0 max-w-xl relative md:w-1/4 w-full mt-4
    `}
    z-index: ${index};
  `

  const activeCategory = items.find((x) => x.active)

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #e5e5e5",
      borderRadius: "0.125rem",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #e5e5e5",
      },
      cursor: "pointer",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? TwConfig.theme.extend.colors.mediumgrey
        : "fff",
      color: TwConfig.theme.extend.colors.darkgrey,
      cursor: "pointer",
    }),
  }

  const populateList = () => {
    // for other items
    const optionItems =
      type === "category" ? items : items.find((x) => x.active).data
    const options = optionItems.map((node) => {
      return {
        value: node.id,
        all: false,
        type,
        label: node.fields.name,
        uiActive: node.uiActive,
      }
    })
    // for "all" item
    if (allButton === true) {
      options.splice(0, 0, {
        value: "all",
        all: true,
        type,
        label: `All ${activeCategory.fields.name.toLowerCase()}s`,
        uiActive: !activeCategory.data.find((x) => x.uiActive),
      })
    }
    return options
  }

  const onChange = (e) => {
    e.all
      ? handleClickSubcategoryAll(items, setItems, e)
      : type === "category"
      ? handleClickCategory(items, setItems, e)
      : handleClickSubcategoryFilterTypeB(items, setItems, e)
  }

  return (
    <Div>
      <Select
        escapeClearsValue
        options={populateList()}
        onChange={onChange}
        defaultValue={populateList()[0]}
        value={populateList().find((x) => x.uiActive)}
        isSearchable={false}
        styles={customStyles}
      />
    </Div>
  )
}

export default GraphControlsDropdown
