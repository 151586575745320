import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import tw, { styled } from "twin.macro"

const Div = styled.div`
  ${tw`
  lg:pl-16 p-2 mr-0 max-w-xl relative hidden md:block
  `}
  z-index: 2;
`

const ArticleCard = ({ items }) => {
  // all subcategories for current category
  const allSubcategories = items.find((x) => x.active).data
  // the one current uiActive category (only one will be active at a time)
  const currentUiActive = allSubcategories.find((x) => x.uiActive)
  const currentSubcategoryId = currentUiActive ? currentUiActive.id : "none"

  return (
    <StaticQuery
      query={graphql`
        query SomeName {
          allMarkdownRemark {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  subcategoryId
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return currentSubcategoryId === node.frontmatter.subcategoryId ? (
              <Div key={node.frontmatter.subcategoryId}>
                <div className="mt-2 md:mt-0 text-md rounded-md font-bold p-2 mr-2 focus:outline-none border border-blue-900 text-blue-900 bg-white">
                  <h3 className="mt-2 text-xl font-serif font-bold text-blue-900 leading-tight">
                    {node.frontmatter.title}
                  </h3>
                  <p
                    className="mt-2 md:mt-4 text-xs font-serif text-gray-900 font-normal"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description,
                    }}
                  />
                  <Link to={node.fields.slug}>
                    <button
                      type="submit"
                      className="mt-2 md:mt-4 text-sm rounded-md font-bold p-2 mr-2 focus:outline-none border border-blue-900 bg-blue-900 text-white uppercase hover:bg-white hover:text-blue-900"
                    >
                      read article
                    </button>
                  </Link>
                </div>
              </Div>
            ) : null
          })}
        </div>
      )}
    />
  )
}

export default ArticleCard
