import React, { useState, useEffect } from "react"
import { uniqBy, filter, cloneDeep } from "lodash"
import GraphCanvas from "./graphCanvas"
// import GraphControlsButton from "./graphControlsButton"
import GraphControlsDropdown from "./graphControlsDropdown"
import ArticleCard from "./articleCard"
import { Categories, rawGraphData } from "../utils/graphImports"

// the subcategoires dictate what companies are shown.
// the "all" boolean dictate if all subcategoires are shown or just one

// function to call when filtering
const setFilteredGraphDataFunction = (categories) => {
  const temp = {
    nodes: [],
    links: [],
  }

  // add category node and assocciated links if "all" boolean is true
  // get one category that is active
  const activeCategory = categories.find((x) => x.active)
  if (activeCategory.all) {
    const categoryNode = rawGraphData.nodes.find(
      (node) => node.id === activeCategory.id
    )
    // push that single node
    temp.nodes.push(cloneDeep(categoryNode))
    // push all its links - no duplicates
    const assocciatedLinks = filter(
      rawGraphData.links,
      (link) => link.source === activeCategory.id
    )
    temp.links.push(...cloneDeep(assocciatedLinks))
  }

  // loop through nodes to extract subcategory nodes
  // condition: if type of node is subcategory, and both category and subcategory are active
  const subcategoryNodes = filter(
    rawGraphData.nodes,
    (node) => node.type === "subcategory"
  )
  subcategoryNodes.forEach((node) => {
    const currentCategory = categories.find((x) => x.id === node.belongsTo)
    const currentSubcategory = currentCategory.data.find(
      (x) => x.id === node.id
    )
    if (currentCategory.active && currentSubcategory.active) {
      // push that single node
      temp.nodes.push(cloneDeep(node))
      // push all its links - no duplicates
      const assocciatedLinks = filter(
        rawGraphData.links,
        (link) => link.source === node.id
      )
      temp.links.push(...cloneDeep(assocciatedLinks))
      // push all the target nodes (might have duplicates)
      const assocciatedLinksTargets = assocciatedLinks.map(
        (link) => link.target
      )
      const associatedNodes = filter(rawGraphData.nodes, (_node) =>
        assocciatedLinksTargets.includes(_node.id)
      )
      temp.nodes.push(...cloneDeep(associatedNodes))
    }
  })
  // node duplicate cleanup
  temp.nodes = uniqBy(temp.nodes, "id")
  return temp
}

const GraphContainer = () => {
  // set 2 states
  const [filteredGraphData, setFilteredGraphData] = useState({
    nodes: [],
    links: [],
  })
  const [categories, setCategories] = useState(Categories)

  useEffect(() => {
    setFilteredGraphData(setFilteredGraphDataFunction(categories))
  }, [categories])

  return (
    <>
      <GraphControlsDropdown
        items={categories}
        setItems={setCategories}
        type="subcategory"
        allButton
        index={3}
      />
      <GraphCanvas graphData={filteredGraphData} />
      <ArticleCard items={categories} />
    </>
  )
}

export default GraphContainer

// buttons instead of dropdown list
// <GraphControlsButton items={categories} setItems={setCategories} type={"category"}/>
// <br/>
// <GraphControlsButton items={categories} setItems={setCategories} type={"subcategory"} allButton={true}/>

// the category dropdown
// <GraphControlsDropdown items={categories} setItems={setCategories} type={"category"} index={4}/>
