import React from "react"
import { graphql } from "gatsby"
//
import Layout from "../components/layout"
import SEO from "../components/seo"
import GraphContainer from "../components/graphContainer"

const Index = ({ data, location }) => {
  const { title, description } = data.site.siteMetadata

  return (
    <Layout location={location} title={title} description={description}>
      <SEO title="Home" description={description} />
      <GraphContainer />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
